<template>
    <v-card>
        <v-toolbar fixed dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('edit_record')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-form>
                <v-container>
                    <v-row>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.name"
                                          :label="$t('full_name') "
                                          required outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.mobileNumber"
                                           :label="$t('mobile_number')"
                                          required outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.email"
                                          :label="$t('email')"
                                          required outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-autocomplete v-model="customerData.faculty"
                                            :label="$t('faculty')"
                                            required outlined dense
                                            :items="faculties"
                                            item-text="value"
                                            item-value="id"></v-autocomplete>
                        </v-col>
                        <!--<v-col cols="12"
                               sm="6"
                               md="4">
                            <v-menu v-model="snackbar.dateMenu3"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="customerData.enrollYear"
                                                  label="Enroll Date"
                                                  clearable
                                                  prepend-icon="event"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.enrollYear">

                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-menu v-model="snackbar.dateMenu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="customerData.passedYear"
                                                  label="Passed Date"
                                                  clearable
                                                  prepend-icon="event"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.passedYear">

                                </v-date-picker>
                            </v-menu>
                        </v-col>-->
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.address"
                                           :label="$t('address')"
                                          required outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model.number="customerData.amount"
                                          type="number"
                                          :label="$t('amount')"
                                          required outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model.number="customerData.totalAmount"
                                          type="number"
                                         :label="$t('total_amount')"
                                          required outlined dense></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="rgb(157,25,25)" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="editFormSubmit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        name: "AcademicEditForm",
        data() {
            return {
                customerData: {},
                faculties: [],
                message: {
                    status: true,
                    text: 'true',
                },
                snackbar: {
                    dateMenu3: false,
                    dateMenu2: false,
                },
                servicesData:{
                    data:{},
                    message:"",
                    color:""
                }
            }
        },
        props: ['editItemIndex'],
        computed: {},
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                const {data} = await axios.get('Academic/DDLAcademicDetailListAsync')
                this.faculties = data

            },
            editFormSubmit() {
                axios.post('Academic/UpdateStudentDetailAsync', this.customerData)
                    .then((response) => {
                        if(response.data.success){
                            this.message.status = true
                            this.servicesData.data=response.data
                            this.servicesData.message="Academic updated Succcessfully"
                            this.servicesData.color="success"
                            this.dialogueClose()
                        }
                    })
                    .catch((response) => {
                        this.servicesData.data=response.data
                        this.servicesData.message="Error Adding Service, Please Contact Admin"
                        this.servicesData.color="error"
                        this.dialogueClose()
                    })
            },
            async getEditData() {
                const {data} = await axios.get('Academic/GetStudentDetailByIDAsync/' + this.editItemIndex)
                this.customerData = data

            }
        },
        mounted() {
            this.getEditData()
            this.formData()
        },
    }
</script>

<style scoped>

</style>